<template>
  <qtm-content-block collapsible title="Invoice Info">
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label v-if="isStaff && withOwner" label="Invoice Owner" required>
          <user-select
            placeholder="Select an owner for this invoice"
            class="mb-4"
            clearable
            :error-messages="!owner ? errors.owner : undefined"
            hide-details
            return-object
            :model-value="owner"
            @update:model-value="$emit('update:owner', $event)"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label class="mb-4" label="Invoice Number" required>
          <qtm-text-field
            :error-messages="errors.invoiceNumber"
            hide-details="auto"
            :maxlength="maxlength"
            :model-value="invoiceNumber"
            @update:model-value="$emit('update:invoice-number', $event)"
          />
        </qtm-input-label>
      </v-col>
      <v-col v-if="matchingInvoice && supplier" v-bind="cols" align-self="end" class="px-4 pb-4">
        <v-alert>
          <template v-slot:prepend>
            <v-icon color="error" icon="mdi-information-outline" />
          </template>
          Invoice with this number already exists for supplier "{{ supplier.name }}":
          {{ matchingInvoice.invoice_number }}
          <nuxt-link target="_blank" :to="matchingInvoiceTo">
            <v-icon class="ml-2" color="mid-grey" icon="mdi-open-in-new" />
          </nuxt-link>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label class="mb-4" label="Issue Date" required>
          <date-picker
            close-on-click
            :error-messages="errors.issueDate"
            hide-details="auto"
            :model-value="issueDate"
            placeholder="Select date"
            @update:model-value="$emit('update:issue-date', $event)"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label label="Due Date" :required="dueDateRequired">
          <date-picker
            clearable
            close-on-click
            :error-messages="errors.dueDate"
            hide-details="auto"
            :model-value="dueDate"
            placeholder="Select date"
            @update:model-value="$emit('update:due-date', $event)"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
  </qtm-content-block>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { maxLength, required, requiredIf } from '@vuelidate/validators'
import type { User, Vendor } from '@quotetome/materials-api'
import DatePicker from '@/components/date-picker.vue'
import UserSelect from '@/components/users/user-select.vue'
import useValidation from '@/composables/validation'

export interface Props {
  dueDate: any
  dueDateRequired?: boolean
  fullWidth?: boolean
  invoiceId?: number
  invoiceNumber?: string
  issueDate: any
  maxlength?: number
  owner?: User | null
  supplier?: Vendor | null
  withOwner?: boolean
}

const props = defineProps<Props>()
defineEmits([
  'update:due-date',
  'update:invoice-number',
  'update:issue-date',
  'update:owner',
])

const authStore = useAuthStore()
const isStaff = authStore.isStaff

const matchingInvoice = ref()
const matchingInvoiceTo = computed(() => ({
  name: 'orders-id-invoices-invoice',
  params: { id: matchingInvoice.value?.order, invoice: matchingInvoice.value?.id },
}))

const cols = computed(() => {
  const config: { cols: number, lg?: number, sm?: number } = { cols: 12 }

  if (!props.fullWidth) {
    config.lg = 6
    config.sm = 8
  }

  return config
})

const errorMessages = computed(() => {
  const messages: any = {}

  if (props.maxlength) {
    messages.invoiceNumber = `An invoice number of up to ${props.maxlength} characters is required`
  }

  return messages
})

const rules = computed(() => {
  const usedRules: any = {
    dueDate: { required: requiredIf(() => props.dueDateRequired) },
    invoiceNumber: { required },
    issueDate: { required },
    owner: { required: requiredIf(() => Boolean(isStaff && props.withOwner)) }
  }

  if (props.maxlength) {
    usedRules.invoiceNumber.maxlength = maxLength(props.maxlength)
  }

  return usedRules
})

const { $api, $error } = useNuxtApp()

watch(() => [props.invoiceNumber, props.supplier], debounce(async () => {
  if (!props.invoiceNumber || !props.supplier) {
    matchingInvoice.value = null
    return
  }

  try {
    const [invoice] = await $api.v1.invoices.list({
      cancelled: false,
      exclude_invoice: props.invoiceId,
      invoice_number: props.invoiceNumber,
      limit: 1,
      supplier: props.supplier.id,
    }).then(response => response.data)

    if (!props.invoiceId || invoice?.id !== props.invoiceId) {
      matchingInvoice.value = invoice
    }
  }
  catch (error) {
    $error.report(error)
  }
}, 500), { immediate: true })

const { errors, isValid } = useValidation({
  errorsByKey: errorMessages,
  rules,
  state: toRef(props),
})

defineExpose({ isValid })
</script>
